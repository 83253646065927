export const rtlCharacterRanges = /[\u0590-\u05FF\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/g
export const rtlLanguages = ['ar', 'he', 'fa', 'ur']

function hasEnoughRTL(text: string): boolean {
  const matches: RegExpMatchArray | null = text.match(rtlCharacterRanges)
  return matches !== null && matches.length >= text.length / 3
}

export function isElementRTL(element: Element): boolean {
  const lang = element.getAttribute('lang')
  return rtlLanguages.some((code) => lang?.startsWith(code)) || hasEnoughRTL(element.textContent || '')
}

export function applyRTL(htmlContent: string): string {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlContent, 'text/html')

  if (isElementRTL(doc.documentElement)) {
    doc.documentElement.setAttribute('dir', 'rtl')
  }

  doc.querySelectorAll('*').forEach((element) => {
    if (isElementRTL(element)) {
      element.setAttribute('dir', 'rtl')
    }
  })

  return new XMLSerializer().serializeToString(doc)
}

export function isRTL(html: string): boolean {
  return applyRTL(html) !== html
}

export function detectRTL(html: string): boolean {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')

  if (isElementRTL(doc.documentElement)) {
    return true
  }

  return Array.from(doc.querySelectorAll('*')).some(isElementRTL)
}
